<template>
  <widget-card
    class="base-cars-mileage-card"
  >

    <div class="mileage-wrapper">
      <div class="left-side">
        <div class="header">
          <h3>Aktualny przebieg</h3>
          <h4>{{ resource.meter_status?.status ?? 0}} km</h4>
        </div>

        <details-table
          :entries="entries"
          i18n-group="base.cars.mileage.field"
        />
      </div>
      <div class="right-side">
        <vue-apex-charts
          class="mthChart"
          type="line"
          height="200"
          :options="apexOptions"
          :series="apexSeries"
        />
      </div>
    </div>

    <template #footer>
      <div class="widget-footer">
        <vs-button
          vs-variant="light"
          @click="openCreateModal"
          v-can:store="'base.resource_report'"
        >
          Dodaj
        </vs-button>
        <vs-button
          vs-variant="light"
          @click="$router.push({ name: 'base.cars.mileage', params: { machineId: resource.id } })"
          v-can:show="'base.resource_report'"
        >
          Przejdź do listy raportów
        </vs-button>
      </div>
    </template>

    <machines-mth-report-modal
      @ok="onReportAdd"
      type="car"
      :resourceEditable="false"
    />
  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import { computed } from 'vue';
import DetailsTable from '@/components/ui/DetailsTable.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import useMachineMthCard from '@/hooks/base/machine/useMachineMthCard';
import formatDate from '@/libs/date-formatter';
import VueApexCharts from 'vue-apexcharts';
import MachinesMthReportModal from '../../machines/modals/MachinesMthReportModal.vue';

export default {
  name: 'BaseCarsInsuranceCard',
  props: {
    resource: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const entries = computed(() => ({
      initialMileage: `${props.resource.meter_status_buy ?? 0} km`,
      filedMileage: `${props.resource.meter_status_card_index ?? 0} km`,
    }));
    const { report, updateChart } = useMachineMthCard();
    const updateData = () => {
      updateChart(props.resource.id);
    };
    updateData();

    const onReportAdd = () => {
      emit('report:add');
      updateData();
    };

    const { modalCreateReport } = useBaseModals();
    const openCreateModal = () => {
      modalCreateReport.value.open({ base_resource_id: props.resource.id });
    };

    const getDaysArray = (days) => {
      const today = new Date();
      const daysAgo = new Date();
      daysAgo.setDate(daysAgo.getDate() - days + 1);

      const arr = [];
      for (let dt = new Date(daysAgo); dt <= today; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
      }

      return arr;
    };

    const lastTenDays = getDaysArray(10);
    const categories = computed(() => lastTenDays
      .map((day) => formatDate.reportChart(day)));

    const getFilename = () => {
      const firstDay = formatDate.filename(lastTenDays[0]);
      const lastDay = formatDate.filename(lastTenDays.at(-1));

      return `${props.resource.name} - przebieg (${firstDay} - ${lastDay})`;
    };

    const apexOptions = computed(() => ({
      theme: {
        palette: 'palette13',
      },
      xaxis: {
        categories: categories.value,
      },
      chart: {
        toolbar: {
          export: {
            png: { filename: getFilename() },
            csv: { filename: getFilename() },
            svg: { filename: getFilename() },
          },
          tools: {
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
    }));

    // get mth amounts for each day
    const values = computed(() => {
      if (!report.value.values) return null;
      let highestRecordedMeterStatus = Number(report.value?.prior_meter ?? 0);

      return lastTenDays
        .map((day) => {
          const s = (date) => formatDate.reportChart(date);

          const dayMeterStatuses = report.value.values
            .filter((rep) => s(rep.report_date) === s(day))
            .map((rep) => Number(rep.meter_status));

          const dayHighest = dayMeterStatuses.length ? Math.max(...dayMeterStatuses) : 0;

          if (dayHighest === 0) return highestRecordedMeterStatus;

          highestRecordedMeterStatus = dayHighest;
          return dayHighest;
        });
    });

    const apexSeries = computed(() => ([{
      name: 'Przebieg',
      data: values.value ?? [],
    }]));

    return {
      entries,
      openCreateModal,
      apexOptions,
      apexSeries,
      onReportAdd,
    };
  },
  components: {
    VueApexCharts,
    WidgetCard,
    VsButton,
    DetailsTable,
    MachinesMthReportModal,
  },
};
</script>

<style lang="sass" scoped>
.chart-placeholder
  width: 55%
  height: 85%
  border: 2px dotted rgba(128, 128, 128, 0.4)
  border-radius: 5px
  position: absolute
  display: flex
  align-items: center
  justify-content: center

.inspection-button
  padding: 4px 8px
  font-size: 9pt
  transition: .2s .2s all ease-in-out
  display: flex
  justify-content: flex-start
  overflow: hidden

.base-cars-mileage-card

  .mileage-wrapper
    display: flex
    justify-content: space-between

    .right-side
      max-width: 60%
      width: 100%

  .last-mileage
    margin-top: 20px

    &-header
      opacity: 0.6

    &-date
      font-size: 12pt
      display: flex
      align-items: center
      gap: 10px

  .widget-footer
    display: flex
    gap: 10px
    align-items: center
</style>
