var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-cars-details"
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('base-cars-details-card', {
    attrs: {
      "resource": _vm.resource,
      "isLoading": _vm.isLoading
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('base-cars-location-card', {
    attrs: {
      "currentLocation": _vm.currentLocation,
      "futureLocations": _vm.futureLocations
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('base-cars-inspection-card', {
    attrs: {
      "resource": _vm.resource
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('base-cars-service-card', {
    attrs: {
      "resource": _vm.resource,
      "type": "cars"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('base-cars-insurance-card', {
    attrs: {
      "resource": _vm.resource
    },
    on: {
      "create:insurance": _vm.refreshResource
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('base-cars-mileage-card', {
    attrs: {
      "resource": _vm.resource,
      "isLoading": _vm.isLoading
    },
    on: {
      "report:add": _vm.refreshResource
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('base-cars-schedule-card', {
    attrs: {
      "resource": _vm.resource,
      "currentLocation": _vm.currentLocation,
      "futureLocations": _vm.futureLocations
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.files ? _c('attachments-card', {
    attrs: {
      "files": _vm.files
    }
  }) : _vm._e()], 1)], 1)], 1), _c('base-service-create-modal', {
    on: {
      "ok": _vm.refreshResource
    }
  }), _c('base-resource-edit-modal', {
    attrs: {
      "type": "car"
    },
    on: {
      "ok": _vm.refreshResource
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }