<template>
  <widget-card
    class="base-cars-details-card"
    with-controls
    :title="title"
    @edit="openEditModal"
    @delete="deleteResource"
  >

    <vs-loader
      full
      text="Pobieranie danych maszyny..."
      v-if="isLoading"
    />

    <details-table
      :entries="entries"
      i18n-group="base.cars.details.field"
    >
      <template #value-status>
        <vs-status-badge :data="resource.status" />
      </template>

      <template #value-company>
        <crm-company-label
          :integrated="resource.last_activity?.collaboration?.company"
          short
        />
      </template>

      <template #value-user>
        <cadre-employee-label
          name-only
          :data="resource.employee"
        />
      </template>

      <template #value-gps>
        <a
          :href="resource.gps_panel"
          class="gps-panel-link"
          target="_blank"
        >
          <feather-icon icon="ExternalLinkIcon" />
          Otwórz w nowej karcie
        </a>
      </template>

      <template #value-card>
        <div class="value-card">
          {{ entries.card }}

          <template v-if="pinVisible && resource.fuel_card_pin">
            <span
              @click="togglePinVisibility"
              class="fuel-card-pin-exposed"
            >
              {{ resource.fuel_card_pin }}
            </span>
          </template>
          <template v-else-if="resource.fuel_card_pin">
            <vs-button
              class="pin-button"
              vs-variant="light"
              @click="togglePinVisibility"
            >
              <feather-icon class="icon" icon="KeyIcon" />
              PIN
            </vs-button>
          </template>

        </div>
      </template>

    </details-table>

    <template #footer
      v-if="resource.category?.be_reported"
    >
      <div class="footer-button-wrapper">
        <vs-button
          vs-variant="light"
          @click="navigateToMileage"
        >
          Kilometrówka
        </vs-button>

        <vs-button
          vs-variant="light"
          class="qr-button"
          @click="modalResourceQR.visible = true"
          v-can:store="'base.resource_report'"
        >
          <feather-icon
            icon="DivideSquareIcon"
            class="qr-button-icon"
          />
          Pobierz kod QR
        </vs-button>
      </div>
    </template>

    <base-resource-qr-modal
      :resource="resource"
    />

  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import DetailsTable from '@/components/ui/DetailsTable.vue';
import VsStatusBadge from '@/components/ui/vs-status-badge/VsStatusBadge.vue';
import CadreEmployeeLabel from '@/components/views/cadre/ui/CadreEmployeeLabel.vue';
import { computed, ref } from 'vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import formatDate from '@/libs/date-formatter';
import CrmCompanyLabel from '@/components/module/crm/CrmCompanyLabel.vue';
import { useRouter } from '@/@core/utils/utils';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import showToast from '@/libs/toasts';
import { DeleteResourceError, DeleteResourceSuccess } from '@/libs/toasts/feedback/module/base';
import useBaseApi from '@/hooks/base/useBaseApi';
import BaseResourceQrModal from '../../resource/modals/BaseResourceQRModal.vue';

export default {
  name: 'MachineDetailsCard',
  props: {
    resource: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const resource = computed(() => props.resource ?? {});

    const entries = computed(() => ({
      status: resource.value.status,
      group: resource.value.last_activity?.group?.name,
      user: 'Jan Kowalski',
      vin: resource.value.serial_number,
      year: resource.value?.production_date,
      company: resource.value?.last_activity?.collaboration?.company?.name ?? null,
      end: formatDate.rent(resource.value?.last_activity?.collaboration?.end_date),
      card: resource.value?.fuel_card,
      gps: resource.value?.gps_panel,
    }));

    const title = computed(() => `${props.resource.name}`);

    const { modalResourceQR, modalEditResource } = useBaseModals();
    const openEditModal = () => {
      modalEditResource.value.open({ ...resource.value });
    };

    const pinVisible = ref(false);
    const togglePinVisibility = () => {
      pinVisible.value = !pinVisible.value;
    };

    const { router } = useRouter();
    const navigateToMileage = () => {
      router.push({
        name: 'base.cars.mileage',
        params: {
          machineId: resource.value.id,
        },
      });
    };

    const deleteResource = async () => {
      const confirmation = await deleteModal({
        title: i18n.t('base.machines.modal.delete.title'),
        text: i18n.t('base.machines.modal.delete.text', {
          name: resource.value.name,
        }),
      });

      if (!confirmation) return;

      useBaseApi()
        .deleteResource(resource.value.id)
        .then(() => {
          showToast(DeleteResourceSuccess, { name: resource.value.name });
          router.push({ name: 'base.cars' });
        })
        .catch(() => {
          showToast(DeleteResourceError);
        });
    };

    return {
      entries,
      title,
      openEditModal,
      pinVisible,
      togglePinVisibility,
      navigateToMileage,
      deleteResource,
      modalResourceQR,
    };
  },
  components: {
    WidgetCard,
    VsButton,
    DetailsTable,
    VsStatusBadge,
    CadreEmployeeLabel,
    VsLoader,
    CrmCompanyLabel,
    BaseResourceQrModal,
  },
};
</script>

<style lang="sass" scoped>
.base-cars-details-card
  .gps-panel-link
    display: flex
    align-items: center
    gap: 5px

  .fuel-card-pin-exposed
    background: var(--code-background)
    padding: 1px 5px
    border-radius: 5px

  .vs-loader
    top: 0

  .footer-button-wrapper
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 5px

    .qr-button
      &-icon
        margin-right: 10px

  .value-card
    display: flex
    align-items: center
    gap: 10px

    .pin-button
      padding: 3px 8px
      font-size: 9pt

      .icon
        margin-right: 5px
</style>
