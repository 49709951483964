var render = function render(){
  var _vm$resource$meter_st, _vm$resource$meter_st2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    staticClass: "base-cars-mileage-card",
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "widget-footer"
        }, [_c('vs-button', {
          directives: [{
            name: "can",
            rawName: "v-can:store",
            value: 'base.resource_report',
            expression: "'base.resource_report'",
            arg: "store"
          }],
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": _vm.openCreateModal
          }
        }, [_vm._v(" Dodaj ")]), _c('vs-button', {
          directives: [{
            name: "can",
            rawName: "v-can:show",
            value: 'base.resource_report',
            expression: "'base.resource_report'",
            arg: "show"
          }],
          attrs: {
            "vs-variant": "light"
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push({
                name: 'base.cars.mileage',
                params: {
                  machineId: _vm.resource.id
                }
              });
            }
          }
        }, [_vm._v(" Przejdź do listy raportów ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "mileage-wrapper"
  }, [_c('div', {
    staticClass: "left-side"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('h3', [_vm._v("Aktualny przebieg")]), _c('h4', [_vm._v(_vm._s((_vm$resource$meter_st = (_vm$resource$meter_st2 = _vm.resource.meter_status) === null || _vm$resource$meter_st2 === void 0 ? void 0 : _vm$resource$meter_st2.status) !== null && _vm$resource$meter_st !== void 0 ? _vm$resource$meter_st : 0) + " km")])]), _c('details-table', {
    attrs: {
      "entries": _vm.entries,
      "i18n-group": "base.cars.mileage.field"
    }
  })], 1), _c('div', {
    staticClass: "right-side"
  }, [_c('vue-apex-charts', {
    staticClass: "mthChart",
    attrs: {
      "type": "line",
      "height": "200",
      "options": _vm.apexOptions,
      "series": _vm.apexSeries
    }
  })], 1)]), _c('machines-mth-report-modal', {
    attrs: {
      "type": "car",
      "resourceEditable": false
    },
    on: {
      "ok": _vm.onReportAdd
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }