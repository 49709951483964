<template>
  <div class="base-cars-details">
    <b-container fluid>
      <b-row>
        <b-col cols="4">
          <base-cars-details-card :resource="resource" :isLoading="isLoading" />
        </b-col>

        <b-col cols="2">
          <base-cars-location-card
            :currentLocation="currentLocation"
            :futureLocations="futureLocations"
          />
        </b-col>

        <b-col cols="3">
          <base-cars-inspection-card :resource="resource" />
        </b-col>

        <b-col cols="3">
          <base-cars-service-card :resource="resource" type="cars" />
        </b-col>

        <b-col cols="6">
          <base-cars-insurance-card
            :resource="resource"
            @create:insurance="refreshResource"
          />
        </b-col>

        <b-col cols="6">
          <base-cars-mileage-card
            :resource="resource"
            :isLoading="isLoading"
            @report:add="refreshResource"
          />
        </b-col>

        <b-col cols="6">
          <base-cars-schedule-card
            :resource="resource"
            :currentLocation="currentLocation"
            :futureLocations="futureLocations"
          />
        </b-col>

        <b-col cols="6">
          <attachments-card v-if="files" :files="files" />
        </b-col>
      </b-row>
    </b-container>

    <base-service-create-modal @ok="refreshResource" />

    <base-resource-edit-modal type="car" @ok="refreshResource" />
  </div>
</template>

<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue';
import { computed, ref } from 'vue';
import useBaseApi from '@/hooks/base/useBaseApi';
import BaseCarsDetailsCard from '@/components/views/base/cars/widgets/BaseCarsDetailsCard.vue';
import BaseCarsLocationCard from '@/components/views/base/cars/widgets/BaseCarsLocationCard.vue';
import BaseCarsInspectionCard from '@/components/views/base/cars/widgets/BaseCarsInspectionCard.vue';
import BaseCarsServiceCard from '@/components/views/base/cars/widgets/BaseCarsServiceCard.vue';
import BaseCarsInsuranceCard from '@/components/views/base/cars/widgets/BaseCarsInsuranceCard.vue';
import BaseCarsMileageCard from '@/components/views/base/cars/widgets/BaseCarsMileageCard.vue';
import BaseCarsScheduleCard from '@/components/views/base/cars/widgets/BaseCarsScheduleCard.vue';
import BaseResourceEditModal from '@/components/views/base/resource/modals/BaseResourceEditModal.vue';
import BaseServiceCreateModal from '@/components/views/base/service/modals/BaseServiceCreateModal.vue';
import { useRouter } from '@/@core/utils/utils';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import RemoteResourceManager from '@/libs/RemoteResourceManager';
import AttachmentsCard from '@/components/views/base/resource/widgets/AttachmentsCard.vue';

export default {
  name: 'CarsDetails',
  setup() {
    const { route } = useRouter();
    const resourceId = computed(() => route.value.params.id);
    const resource = ref({ id: resourceId.value });
    const isLoading = ref(false);

    const refreshResource = () => {
      isLoading.value = true;
      RemoteResourceManager.getInstance().notify('base.resource');
      useBaseApi()
        .fetchResource(resourceId.value)
        .then(({ data }) => {
          resource.value = {
            ...data.data,
          };
        })
        .finally(() => {
          isLoading.value = false;
        });
    };
    refreshResource();

    const currentLocation = ref(null);
    const futureLocations = ref([]);
    useInvestmentsApi()
      .getAllLocations(resource.value.id)
      .then(({ data }) => {
        currentLocation.value = data.data.current_location ?? null;
        futureLocations.value = data.data.future_locations.filter(
          (location) => location.id !== currentLocation.value?.id,
        ) ?? null;
      });

    const files = computed(() => resource.value.files);

    return {
      currentLocation,
      futureLocations,
      resource,
      refreshResource,
      isLoading,
      files,
    };
  },
  components: {
    BContainer,
    BRow,
    BCol,
    BaseCarsDetailsCard,
    BaseCarsLocationCard,
    BaseCarsInspectionCard,
    BaseCarsServiceCard,
    BaseCarsInsuranceCard,
    BaseCarsMileageCard,
    BaseCarsScheduleCard,
    BaseResourceEditModal,
    BaseServiceCreateModal,
    AttachmentsCard,
  },
};
</script>

<style lang="sass" scoped></style>
